import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Article from "../components/article";

export default ({ data }) => {
  const subtitle =
    "A multiplatform (Desktop, Smartphones, Smartwatch) information resource" +
    " that supports the charging needs of electric vehicle (EV) owners in the" +
    " city of Toronto";

  return (
    <Layout indexPage={false}>
      <SEO title="Evie" keywords={["evie", "ux"]} />
      <Article
        title="Evie"
        subtitle={subtitle}
        imgSrc={data.evie.publicURL}
        imgAlt="Evie"
      >
        <div className="my-16" />
        <p>
          For this project, we were asked to provide a solution for electric
          vehicles to support the needs of current owners and encourage
          adoption. After researching the problem, we realized that Toronto’s
          charging infrastructure is still growing. As such, EV owners need
          easy, centralized access to information about charging infrastructure
          in order to charge their car, plan their trips and travel without fear
          of being stranded with no charge. Therefore, we decided to design
          (Evie) which is A multiplatform (Desktop, Smartphones, Smartwatch)
          information resource that supports the charging needs of electric
          vehicle (EV) owners in the city of Toronto. To pitch the idea, I
          developed a storyboard to help us demonstrate how the product fits in
          the day to day activities of the user.
        </p>
        <img
          className="my-16"
          src={data.evieStoryBoard.publicURL}
          alt="Evie Story Board"
        />
        <p>
          I was involved in the different of the project and my focus on this
          project was on designing the smartwatch version of the solution.
        </p>
        <br />
        <p>
          We started the project with user research and developing personas.
          Then we moved to develop a list of user requirements and developing
          low fidelity sketches.
        </p>
        <img
          className="my-16"
          src={data.evieLowFid.publicURL}
          alt="Evie Low Fidelity"
        />
        <p>
          Then I developed a medium fidelity prototype using Balsamiq to be used
          for the usability testing session to collect user feedback and improve
          on it accordingly.
        </p>
        <img
          className="my-16"
          src={data.evieMedFid.publicURL}
          alt="Evie Low Fidelity"
        />
        <p>
          The data collected during the usability session were then used to
          develop the final high fidelity prototype using Axure.
        </p>
        <img
          className="my-16"
          src={data.evieHighFid1.publicURL}
          alt="Evie Low Fidelity"
        />
        <img
          className="my-16"
          src={data.evieHighFid2.publicURL}
          alt="Evie Low Fidelity"
        />
      </Article>
    </Layout>
  );
};

export const query = graphql`
  query {
    evie: file(relativePath: { eq: "evie.png" }) {
      publicURL
    }

    evieStoryBoard: file(relativePath: { eq: "evie_story_board.png" }) {
      publicURL
    }

    evieLowFid: file(relativePath: { eq: "evie_low_fid.jpg" }) {
      publicURL
    }

    evieMedFid: file(relativePath: { eq: "evie_medium_fid.png" }) {
      publicURL
    }

    evieHighFid1: file(relativePath: { eq: "evie_high_fid_1.png" }) {
      publicURL
    }

    evieHighFid2: file(relativePath: { eq: "evie_high_fid_2.png" }) {
      publicURL
    }
  }
`;
